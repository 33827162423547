<template>
<v-card>

    <v-container>
        <v-row>
            <div class="col-md-12 col-sm-12">
                <v-toolbar elevation="5" dense>
                    <v-toolbar-title>Buscar Activo Fijo <v-spacer></v-spacer> <a target="_blank" style="font-size: 10px" :href="activeCamere">{{activeCamere}}</a></v-toolbar-title>
                </v-toolbar>
                <v-card outlined>
                    <v-container>
                        <s-scanner-qr :readonly="false" :config="configScann" autofocus return-object @onValue="onValue($event)" @onData="onData($event)" @returnObj="responseObj($event)"></s-scanner-qr>
                    </v-container>
                    <v-card-text class="pb-5">
                        <v-container>
                            <v-row align="center" justify="center">
                                <v-col cols="12" lg="12" md="12" sm="12">
                                    <v-alert v-if="itemQR == null" style="border-radius: 10px 10px 10px 10px; overflow: auto; width: 100%; right: 0px; top: 5%;" border="right" color="error" dark dense shaped close-icon="fas fa-times-circle">
                                        <!-- v-card-text>No Existe ningún Cliente con ese Nro de Documento</v-card-text> -->
                                        <span style="font-family: Calibri; font-size: 13px">Upps, No se puedo encontrar un Activo Fijo y/o no ha realizo una busqueda</span>
                                    </v-alert>
                                    <v-card v-if="itemQR !== null" class="mx-auto my-1" outlined>
                                        <v-alert style="border-radius: 10px 10px 10px 10px; overflow: auto; width: 100%; right: 0px; top: 5%;" border="right" color="info" dark dense shaped close-icon="fas fa-times-circle">
                                            <span style="font-family: Calibri; font-size: 13px">Principales resultados:</span>
                                        </v-alert>

                                        <v-card-text>
                                            <div class="text-subtitle-1">
                                                <b>Tipo Activo Fijo:</b><br>
                                                <ul>
                                                    <li style="margin-left: 130px">
                                                        <div><b>{{itemQR.TypeActiveName}}</b></div>
                                                    </li>
                                                </ul>

                                            </div>
                                            <div class="text-subtitle-1">
                                                <b>Codigo Activo Fijo:</b> <br>

                                                <ul>
                                                    <li style="margin-left: 130px">
                                                        <div><b>{{itemQR.FasCodeActive}}</b></div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="text-subtitle-1">
                                                <b>Descripción: </b><br>
                                                <ul>
                                                    <li style="margin-left: 130px">
                                                        <div>{{itemQR.FasDescription}}</div>
                                                    </li>
                                                </ul>

                                            </div>
                                            <div class="text-subtitle-1">
                                                <b>Fecha Registro:</b><br>

                                                <ul>
                                                    <li style="margin-left: 130px">
                                                        <div><b>{{itemQR.SecCreate}}</b></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </div>
        </v-row>

    </v-container>

</v-card>
</template>

<script>
import _sSearchActiveFixed from "@/services/Inventory/InvFixedAssets"

export default {
    components: {},

    props: {},

    data() {
        return {
            configScann: {
                service: _sSearchActiveFixed
            },
            info: "",
            codeqr: {},
            activeCamere: "chrome://flags/#unsafely-treat-insecure-origin-as-secure",
            itemQR: null
        }
    },

    methods: {

        onValue(val) {

        },

        onData(val) {
            let codeqrarray = val.split(",");
            this.codeqr = codeqrarray;
            //this.info = "# Jabas: " + codeqrarray[1] + " || " + codeqrarray[2] + " || " + codeqrarray[3] + " || " + codeqrarray[4] + " || " + codeqrarray[5] + " || " + codeqrarray[6]
        },

        responseObj(resp) {
           
            if(resp !== null){
                resp.SecCreate = this.$moment(resp.SecCreate).format(this.$const.FormatDateTimeDB);
            }
            this.itemQR = resp
            //this.$refs.textQR.focus()

        }

    },

}
</script>
